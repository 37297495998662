.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cartCenter {
  background-color: transparent;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'inter';
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'inter';
}
.style_name {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  background: rgb(0 0 0 / 40%);
  padding: 0 5px;
}
.moreDataLoader {
  background-color: transparent;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
  font-family: 'inter';
}

.App-link {
  color: #61dafb;
}

.bgheader{
  background: rgb(0 0 0 / 40%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wm {
  max-width: 1920px;
  margin: auto;
  min-width: 600px;
}

.mmw {
  max-width: 1920px;
  min-width: 360px;
  margin: auto;
}

.w_55 {
  width: 55%;
}

.w_70 {
  width: 70%;
}

.w_80 {
  width: 80%;
}

.w_83 {
  width: 83%;
}

.w_85 {
  width: 85%;
}

.w_90 {
  width: 90%;
}

.h_720 {
  height: 720px;
}

.red_clr {
  color: #E77171 !important;
}

.green_clr {
  color: #1FFC33 !important;
}

.dr_clr {
  color: #FF0000;
}

.bg_green_clr {
  background-color: #1FFC33 !important;
}

.muted_clr {
  color: #7A7A7A !important;
}

.W_fit {
  width: fit-content !important;
}

.product_text_clr {
  color: #252525 !important;
}

.mp_img_mh {
  max-height: 634px;
}

.filterSort {
  max-width: 180px;
  height: 30px;
}

.bgShadow{
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}


.mp_text_length {
  height: 33 px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.p_text_length {
  height: 33 px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mpswb {
  height: 25px;
}

.border_clr {
  border: 1px solid #1FFC33;
}

.tw_right {
  text-align: -webkit-right;
}

.py_3 {
  padding-top: .70rem;
  padding-bottom: .70rem;
}

.p_65R {
  padding-top: .65rem
}


.outBtnCSs {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
  /* width: 45%;
  height: 35px; */
}

.greenBtn {
  background: #1FFC33;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 7px 22px;
  width: 45%;
  height: 35px;
}

.buttonDownload {
  border-color: #1FFC33;
  border-radius: 8px;
  background-color: white;
  border-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.dangerBtn {
  color: #F93E3E;
  border: 1.5px solid #F93E3E;
}

.successBtn {
  border: 1.5px solid #1FFC33;
}

.disableBtn {
  border: 1px solid #D6D6D6;
}

.bgDisbaleBtn {
  background: #F5F5F5;
}

/* -----------------------bootstrap-------------------------- */
.form-control {
  color: none !important;
  /* background-color: #E5E5E5 !important; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
  color: none !important;
  background-color: none !important;
  /* border-color: transparent !important; */
  /* outline: 0 !important; */
  box-shadow: none !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none!important;
}
.form-control.is-valid, .was-validated .form-control:valid {
  background-image: none!important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  content: none !important;
  display: none !important;
}

.toast {
  width: fit-content !important;
}

.modal-90w {
  max-width: 1920px !important;
  top: 7% !important;
}

.modal-backdrop {
  background-color: transparent !important;
}

.w230 {
  width: 230px !important;
}


/* --------------------------Font Size----------------------- */
.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_16 {
  font-size: 16px !important;
}

.fs_20 {
  font-size: 20px;
}

.fw_5 {
  font-weight: 500;
}

.ls {
  letter-spacing: 0.13em;
}

.ls6 {
  letter-spacing: 0.06em
}

/* --------------------------Margin----------------------- */
.mtn_62 {
  margin-top: -62px !important;
}

.mtn_10 {
  margin-top: -10px !important;
}

/* --------------------------Padding----------------------- */
.py_1 {
  padding-top: .20rem;
  padding-bottom: .20rem;
}

/* --------------------------border----------------------- */
.br_5 {
  border-radius: 5px !important;
}

.br_10 {
  border-radius: 10px !important;
}


.psc {
  place-self: center;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  box-shadow: none !important;
  border: none !important;
}



.btn_clr {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  border: none !important;
}

.mouse {
  cursor: pointer;
}

.tw_center {
  text-align: -webkit-center;
}

.obj_fit {
  object-fit: cover;
}

.accordion_btn .accordion-button {
  padding: .25rem 0.25rem !important;
}

.success_tost_position {
  position: fixed;
  z-index: 9999;
  text-align: center;
  width: 348px;
  left: calc(50% - 174px);
  top: 30%;
  background: #fff !important;
}

/* ------------------Header--------------- */
.search_icon {
  margin-left: 7px;
  margin-top: 12px;
}

.search_suggestion {
  padding: 5px 0;
  max-height: 330px !important;
  overflow: auto;
  cursor: pointer;
}

.top_0 {
  top: 0;
}

.customerbtn {
  width: 146px;
  height: 30px;
  background: #f2f2f2;
  border: none;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
}

.regbtn {
  background: #1ffc33;
  border: none;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding: 9px 40px;
}

.cartIcon {
  position: absolute;
  margin-left: -33px;
  height: 22px;
  margin-top: 5px;
}

.cartLength {
  position: absolute;
  margin-left: -26px;
  margin-top: 5px;
  font-size: 12px;
}

/* ----------------demo footer--------- */
.dfSize {
  font-size: 20px;
}

.mdfSize {
  margin-bottom: 45px;
}

/* ------------------catalogue dropdown css--------------- */
.zindex {
  z-index: 9999;
}

.cate_sub_cate_pe {
  padding-left: 3rem;
}

.cate_img {
  max-height: 250px;
  cursor: pointer;
}

/* ------------------Home ---------------- */
.cate_exp_img {
  max-height: 460px;
  /* min-height: 360px; */
  width: 100%;
}

.cate_expo_rightText {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.home_text {
  max-width: 826px;
  font-size: 25px;
}

/* -----------------Slider---------------- */
.leftarrow {
  left: 0px;
  position: absolute;
  top: 30%;
  background: #fff;
  height: 100px;
  width: 40px;
  padding: 0 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999;
  font-size: 55px;
  cursor: pointer;
}

.productLeftArrow {
  left: 0px;
  position: absolute;
  background: #fff;
  height: 100px;
  top: 22%;
  width: 40px;
  padding: 0 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999;
  font-size: 55px;
  cursor: pointer;
}

/* .leftarrow {
  color: #5E5E5E;
  font-size: 15px;
  right: 40px;
  position: absolute;
  top: -25px;
} */
.rightarrow {
  right: 0px;
  position: absolute;
  background: #fff;
  height: 100px;
  width: 40px;
  top: 30%;
  padding: 0 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 55px;
  cursor: pointer;
}

.productRightArrow {
  right: 0px;
  position: absolute;
  top: 22%;
  background: #fff;
  height: 100px;
  width: 40px;
  padding: 0 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 55px;
  cursor: pointer;
}

.rightarrowCateColl {
  right: 0;
  position: absolute;
  top: 30%;
  font-size: 30px;
}

.leftarrowCateColl {
  left: 0;
  position: absolute;
  top: 30%;
  font-size: 30px;
  z-index: 999;
}

/* .rightarrow{
  color: #5E5E5E;
  font-size: 15px;
  position: absolute;
  right: 20px;
  top: -25px;
} */
.product_img_mg {
  max-height: 350px;
}

.product_detail_wp {
  margin-left: 92px;
  color: #F93E3E;
  font-size: 14px;
}

.bgtext {
  position: relative;
}

.bgtext:before {
  margin: 3rem;
  content: "colle";
  position: absolute;
  /* transform: rotate(300deg);
  -webkit-transform: rotate(300deg); */
  color: rgb(187, 182, 182);
  bottom: 0;
  right: 0;
  z-index: -1;
}

.bb2 {
  border-bottom: 2px solid;
}

/* -------------------Product Details-------------------- */
/* ----------Dealer Model------------ */
.pro_de_model {
  background-color: #F3F6F8;
}

.product_detail_search {
  margin-left: -30px;
  margin-top: 45px;
}

.mainCharBg {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 1.7;
  background: lightgray;
  font-size: 50px;
  font-weight: bold;
  color: gray;
}

/* -------------------image Zoom-------------------- */
.zoom {
  transition: transform .2s;
  width: 100%;
  height: 282px;
  margin: 50px 0;
  object-fit: cover;
}

.zoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  padding: 20px 0;
  /* width: 68%;
  margin-left: 15.5%; */
  /* object-fit: cover; */
}

/* ----------------------Main Cate ------------------ */
.main_cate_img {
  height: 130px;
  width: 130px;
  border: 0.752px solid #D3D3D3;
  border-radius: 10px;
  padding: 25px;
}

/* ---------------------- Project Gallery ------------------ */
.project_G_img {
  height: 300px;
  object-fit: cover;
}

.project_G_main_img {
  height: 625px;
  object-fit: cover;
}

/* ------------------------- Product Category ------------------------------- */
.cate_coll_w .nav-item {
  width: 33% !important;
  text-align: -webkit-center;
}

.cate_coll_border_0,
.nav-tabs .nav-link {
  border: 0px !important;
}

.cate_coll_text_clr_0,
.nav-link,
.nav-link:focus,
.nav-link:hover {
  color: #000000 !important;
}

.cate_coll_text_act.clr,
.nav-link.active {
  color: #000 !important;
  background-color: transparent !important;
  border-bottom: 2px solid #737373 !important;
  font-weight: bold;
}

.cate_coll_img {
  max-height: 160px;
  padding: 20px;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
}

/* .cate_coll_img_f:focus {
  border: 1px solid #1FFC33 !important;
} */

a {
  text-decoration: none !important;
  color: #000 !important;
}

/* ------------------- order list css ----------------------------*/

.order_list_heading {
  font-family: 'inter';
  font-size: 42px;
  font-weight: 500;
}

.orderList_image_holder {
  width: 207px;
  height: 295px;
}

.orderList_name {
  float: left;
  font-size: 14px;
  font-weight: 500;
}

.orderList_price {
  float: right;
  font-size: 14px;
  font-weight: 500;
}

.order_card {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 207px;
  height: 400px;
}

.orderavailabletick {
  width: 14px;
  height: 14px;
}

.order_list_icon {
  width: 20px;
  height: 20px;
}

.description {
  font-family: 'inter';
  font-weight: 300;
}

.card_body {
  width: 351px;
  height: 214px;
}

.order_list_whatsapp {
  width: 48px;
  height: 48px;
}

.orderlist_button {
  background-color: #F2F2F2;
  color: #7c7c7c;
}

.total_order_card_text {
  font-size: 40px;
}

.emptyImg {
  width: 191px;
  height: 191px;
}

/* ----------------------------order_modal_css -----------------------------*/

.modalContent {
  text-align: center;
  align-self: center;
  display: block;
  justify-content: center;
  padding-top: "20px";
}

.modalText {
  font-weight: bold;
}

.catalog_dropdown {
  position: relative;
  left: 25px;
  top: 15px;
}

.modalFloat {
  display: flex;
  justify-content: center;
}

.hrd {
  width: "250px"
}

.closemark_icon {
  float: right;
  margin-top: -10px;
  height: 50px;
}

.column_height {
  display: flex;
  flex-direction: column;
}

.column_image {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #1FFC33;
  border-color: #1FFC33;
  border-style: solid;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.button2 {
  border-color: #1FFC33;
  border-radius: 8px;
  background-color: white;
  border-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
}

.productmodal_chaticon {
  width: 24px;
  height: 24px;
}

.orderModal_width {
  width: 60%;
}

.thankyou_tickmark {
  width: 35.46px;
  height: 35.46px;
  margin-left: 10px;
}

.header_bg_img {
  height: 680px;
  object-fit: cover;
}

.mob_header_bg_img {
  height: 360px;
  object-fit: cover
}

.h25px {
  height: 25px;
}

.rmwcModalX {
  margin-top: -14px;
  font-size: 25px;
  cursor: pointer;
}

.wishRemoveToast {
  position: fixed;
  transform: translateX(-50%) !important;
  left: 50%;
  bottom: 50%;
}

/* ------------------------------------Register Modal----------------------- */
.regModal {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.02em;
}

/* ---------------------- Banner ------------------ */
.banner_img {
  max-height: 678px;
}

.home_product_shadow {
  background: #FFFFFF;
  box-shadow: 0px 0px 5.41437px 2.70718px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.header_input {
  width: 75%
}

/* -----------------------Payment Recipt------------------ */
.buttonSizeDown {
  width: 215px !important;
  background-color: transparent;
  border: 2px solid #1FFC33;
  border-radius: 8px;
  padding: 7px 0 !important;
}

.buttonSizeWhats {
  padding: 7px 30px !important;
  background-color: #1ffc33;
  border-color: #1ffc33;
  border-style: solid;
  border-radius: 8px;
}


/* -------------------------------Product Slider----------------------------------------------------- */
.optionImg {
  width: 100%;
  max-height: 100px;
}

.circles {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #622060;
}

.circles2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1473a9;
}

.circles3 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #519d04;
}

.circles4 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fbdada;
}

/* .btnCirlce {
  height: 35px;
  width: 35px;
} */

.btnCirlceActive {
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 50%) !important;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
  /* We need to change this value */
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

/* ---------------------------------------------PAge Nation------------------------------------ */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  /* padding: 8px 14px; */
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color .2s;
  border: 1px solid #ddd;
  cursor: pointer;

}

.pagination-container div.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #7cbddb;
}

.pagination-container div.disabled {
  opacity: 0.2;
}

.pagination-container div:hover:not(.active) {
  background-color: rgb(238, 238, 238);
}

.page-link {
  border-radius: 5px !important;
}

.image-gallery-slide {
  transition: none !important;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 10px 3px !important;
}

.image-gallery-left-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
  color: #000 !important;
}

.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
  color: #000 !important;
}

.image-gallery-left-nav {
  left: 8px !important;
  border: 1px solid !important;
  background: #fff !important;
  opacity: 0.7 !important;
}

.image-gallery-right-nav {
  right: 8px !important;
  border: 1px solid !important;
  background: #fff !important;
  opacity: 0.7 !important;
}

.image-gallery-icon {
  filter: none !important;
}

.image-gallery-icon:hover {
  color: #fff !important;
}

/* ---------------------------------------------My Orders------------------------------------ */
.myordersimg {
  height: 75px;
  width: 55px;
}

.myordersTrunW {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.myOrderHeight {
  height: 450px;
  overflow: auto;
}

.turnWid {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* ---------------------------------------------JOBS------------------------------------ */
.career_img {
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  /* object-fit: cover; */
}



/* -----------------------------------------------------------------Media Css--------------------------------------------------------------------- */

@media screen and (max-width: 1590px) {
  .mpswb {
    height: 22px;
  }
}

@media screen and (max-width: 1441px) {
  .mpswb {
    height: 19px;
  }

  .px_0 {
    padding: 0px 0 !important;
  }

  .pe_0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 1460px) {

  /* ---------------------- Cate BY Cate ------------------ */
  .cate_slide_img {
    height: 920px;
    object-fit: cover;
  }

  .zoom {
    transition: transform .5s;
    width: 100%;
    height: 375px;
    margin: 70px 0;
  }

  .main_cate_img {
    height: 180px;
    width: 180px;
    padding: 20px;
  }

  /* .main_cate_img {
    height: 125px;
    padding: 20px;
  } */
}

@media screen and (max-width: 1200px) {

  /* ------------------catalogue dropdown css--------------- */
  .cate_sub_cate_pe {
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 991px) {
  .home_head_text {
    font-size: 30px;
  }

  .home_text {
    font-size: 18px;
  }

  .header_text {
    font-size: 12px
  }

  .header_input {
    width: 50%
  }

  /* -------------------------Main Cate------------------------ */
  .main_cate_img {
    height: 100px;
    width: 100px;
    padding: 15px;
  }

  .rightarrow {
    right: -1px;
    top: 25%;
    height: 80px;
    width: 35px;
  }

  .leftarrow {
    left: 0px;
    top: 25%;
    height: 80px;
    width: 35px;
  }

  .header_bg_img {
    height: 460px;
  }

}

@media screen and (max-width: 820px) {

  .rightarrow {
    right: -1px;
    top: 20%;
    height: 75px;
    width: 30px;
  }

  .leftarrow {
    left: 0px;
    top: 20%;
    height: 75px;
    width: 30px;
  }



}

@media (max-width:768px) {
  .btnWidth {
    width: 50%;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 720px) {

  /* -------------------------Main Cate------------------------ */
  .main_cate_img {
    height: 85px;
    width: 85px;
    padding: 15px;
  }

  .rightarrow {
    right: -1px;
    top: 20%;
    height: 75px;
    width: 30px;
    padding: 0 10px;
  }

  .pFS_20 {
    font-size: 20px;
  }

  .pFS_18 {
    font-size: 18px;
  }

  .pFS_16 {
    font-size: 16px;
  }

  .pFS_14 {
    font-size: 14px;
  }

  .pFS_12 {
    font-size: 12px;
  }

  /* ----------------demo footer--------- */
  .dfSize {
    font-size: 16px;
  }

}

@media screen and (max-width: 650px) {

  /* -------------------------Main Cate------------------------ */
  .main_cate_img {
    height: 75px;
    width: 75px;
    padding: 15px;
  }

  .rightarrow {
    right: -1px;
    top: 15%;
    height: 70px;
    width: 25px;
    padding: 0 7px;
  }

  .leftarrow {
    left: 0px;
    top: 15%;
    height: 70px;
    width: 25px;
    padding: 0 7px;
  }

  .header_bg_img {
    max-height: 300px;
  }

}

@media screen and (max-width: 599px) {

  /* -------------------Modal---------------- */
  .popupwrapper {
    overflow-y: auto;
    /* position: sticky!important;  */
    bottom: 0 !important;
    top: auto !important;
    height: auto !important;
  }

  .mm_model .modal-dialog {
    margin: 0 !important;
  }

  .mm_model1 .modal-dialog {
    position: relative !important;
    margin: 0 !important;
  }

  .modal-dialog {
    max-width: 599px !important;
  }

  /* .modal-content {
    display: inline-block !important;
  } */

  .mh_model .modal-content {
    height: auto;
    border: none !important;
    border-radius: 0 !important;
  }

  .modal-backdrop {
    min-width: 350px !important;
  }

  .modalWidth {
    min-width: 360px !important;
  }

  .mob_header_bg_img {
    height: 300px;
  }

  .pb_3_5 {
    padding-bottom: 3.5rem !important;
  }

  .mm50 {
    margin-bottom: 50px;
  }

  /* header */
  .customerbtn {
    height: 30px;
    background: #f2f2f2;
    border: none;
    outline: none;
    font-size: 12px;
    border-radius: 5px;
  }

  .regbtn {
    background: #1ffc33;
    border: none;
    outline: none;
    font-size: 12px;
    border-radius: 5px;
    padding: 9px 30px;
  }

  .cartIcon {
    position: absolute;
    margin-left: -20px;
    height: 17px;
    margin-top: 0px;
  }

  .cartLength {
    position: absolute;
    margin-left: -15px;
    margin-top: -1px;
    font-size: 10px;
  }

  /* ------orders----- */
  .turnWid {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* ------------------------------------Register Modal----------------------- */
  .regModal {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

}

@media screen and (max-width: 460px) {
  .myordersTrunW {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .myOrserFs {
    font-size: 14px;
  }

  .header_bg_img {
    max-height: 222px;
  }

  .mob_header_bg_img {
    height: 222px;
  }
}




/* / -----------------------------Modal------------------------------- / */
.custom_modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 80px;
}

/* / Modal content / */
.modal_content {
  background-color: #1E2022;
  color: #fff;
  width: 50%;
  max-width: 650px;
  height: 455px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
}

/* / Close button / */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* / -----------------------------End Modal-------------------------- / */