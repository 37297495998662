/* --------------------------------------------------------------Home Screen-------------------------------------------------------------- */
.mw390 {
  min-width: 390px !important
}

.pfdx {
  position: fixed
}

.productTabSlider {
  display: -webkit-box;
  overflow: scroll;
  margin: 5px;
}

.productTabSlider::-webkit-scrollbar {
  display: none;
}

.line-break {
  line-break: anywhere;
}

.mainWidth {
  max-width: 599px !important;
  /* min-width: 280px !important; */
  min-width: 360px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.overFlowy {
  overflow-y: scroll !important;
}


.minmaxw {
  min-width: 350px !important;
}

.RPText {
  font-size: 24px;
  font-weight: 500 !important;
}

.cirlcesCS {
  border: 1px solid #000;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

.dashboardImgCS {
  min-height: 222px;
}

.ImgContentCs {
  position: relative;
  width: 100%;
  top: -271px;
}

.walliconCS {
  width: 65px;
}

.CustomerCStxt {
  font-size: 34px;
  font-weight: 500;
}

.optionImg {
  max-width: 80px;
}

.btnCirlce {
  height: 24px !important;
  width: 18px !important;
}

.mobBtnCirlce {
  height: 18px !important;
  width: 18px !important;
}

.accordion-button:not(.collapsed) {
  color: #3A3A3A !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.muted_clr1 .accordion-button {
  color: #7A7A7A;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("https://wallicon.in/assets/icons/negative.svg") !important;
  background-size: 8px !important;
  margin-top: -15px !important;
  box-shadow: none !important;
}

.accordion-button::after {
  background-image: url("https://wallicon.in/assets/icons/plus.svg") !important;
  background-size: 8px !important;
  transform: rotate(-180deg) !important;
  margin-top: -15px !important;
}

.btnCirlceActive {
  box-shadow: 0 0 0 0.18rem rgb(130 138 145 / 50%) !important;
}

.DescriptionImg {
  /* object-fit: cover; */
  max-height: 711px;
}

.productDesImg {
  max-height: 543px;
}

.productDots .slick-dots {
  bottom: 18px !important
}

.productDotsM .slick-dots li {
  margin: 0 !important;
}

.cataIConsCS {
  width: 70px;
  height: 70px;
  border: 0.306238px solid #d3d3d3;
  margin: 5px;
}

.cataIConsCS2 {
  width: 70px;
  height: 70px;
  border: 0.306238px solid #d3d3d3;
}

.iconFotMov {
  width: 15px !important;
  height: 17.25px;
}

.prodDesImg {
  width: 100%;
  height: 530px;
}

.btn_succ_filld {
  background-color: #1FFC33 !important;
  border-radius: 2px;
}

.text_EE {
  color: #EEEEEE;
  font-weight: 300;
  font-size: 12.3243px;
}

.explImgMOV {
  width: 136px !important;
  height: 136px !important;
}

.explImg2MOV {
  width: 81px !important;
  height: 116px !important;
}

.centerTrans {
  transform: scale(1.4) !important;
}

.headText {
  font-size: 10px;
 font-family: 'inter';
  width: max-content;
}

.footerTxt {
  font-size: 10px;
  font-family: 'inter';
  width: max-content;
  font-weight: 300;
}

.accordion-button {
  padding-inline: 0px !important;
}

.setShowModal {
  position: sticky !important;
  bottom: 0 !important;
}

.rowmx3 {
  border: 0.546667px solid #d3d3d3;
  border-radius: 5.46667px;
}

.txtRed {
  color: #ff0000;
}

.text_WH {
  height: 33px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.accordion-button:focus {
  z-index: 3;
  /* border-color: #86b7fe; */
  outline: 0;
  box-shadow: none !important;
}

.footerline {
  font-size: 7px !important;
  width: max-content;
}

.imgTextMOV {
  font-size: 14px !important;
font-family: 'inter';
  width: max-content;
  color: #000;
}

.imgText2MOV {
  font-size: 12px !important;
  font-family: 'inter';
}

.sp_text {
  font-size: 24px;
  font-weight: 300;
}

.priceText {
  font-size: 18px !important;
}

.disabBtn {
  background-color: #f2f2f2 !important;
}

.priceNum {
  font-size: 20px;
  font-weight: 500;
}





.pvcTxt {
  font-size: 12px !important;
  width: max-content !important;
}

.warning_text {
  font-size: 12px;
}

.descText {
  font-size: 10px !important;
  font-weight: 300;
  font-family: 'inter';
}

/* .descText2 {
  font-size: 9px !important;
  font-weight: 300;
 font-family: 'inter';
} */
.cataDiv {
  text-align: -webkit-center;
  height: max-content;
}

.wmx {
  width: max-content !important;
}

.sbMOV {
  width: 22px;
}

.Qty_Dealer {
  font-size: 16px;
}

.astra {
  width: inherit;
}


.filterTxt {
  font-size: 6px;
}

@media screen and (max-width: 994px) and (min-width: 426px) {
  .DescriptionImg {
    width: inherit !important;
  }
}

@media screen and (max-width: 425px) {
  .heartNdelicon {
    height: 12px !important;
    width: 12px !important;
  }

  .CustomerCStxt {
    font-size: 29px !important;
  }

  .explImgMOV {
    max-width: 122px;
    max-height: 122px;
  }

  .explImg2MOV {
    width: 96px !important;
    height: 129px !important;
  }

  .zoomMob {
    max-width: 122px;
  }

  .cataText {
    font-size: 12px !important;
  }

  .mainScroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* .slick-slide.slick-center img {
    transform: scale(1.2) !important;
  } */
  .productDesImg {
    width: 100%;
  }
}

@media screen and (max-width: 394px) {
  .explImgMOV {
    max-width: 96px;
    max-height: 96px;
  }

  .cataText {
    font-size: 10px !important;
    padding-right: 10px;
  }

  .zoomMob {
    width: 94px !important;
  }

  .explImg2MOV {
    max-width: 93px !important;
    max-height: 125px !important;
  }

  .sp_text {
    font-size: 18px;
  }
}

@media screen and (max-width: 375px) {

  /* ------------------Search---------------- */
  .search_Bar {
    width: 350px;
    height: 40px;
    border-radius: 24px;
  }

  .arrow {
    width: 10px;
    height: 10px;
    float: right;
    margin-top: 8px;
  }

  .CustomerCStxt {
    font-size: 29px !important;
  }

  .imgTextMOV {
    font-size: 13px !important;
  }

  .imgText2MOV {
    font-size: 11px !important;
  }

  .cataText {
    font-size: 10px !important;
    padding-right: 0px;
  }

  .explImgMOV {
    width: 107px !important;
    height: 107px !important;
  }

  .explImg2MOV {
    max-width: 80px !important;
    max-height: 125px !important;
  }

  .CustomerCStxt {
    font-size: 26px !important;
  }



  .priceNum {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .textTable {
    font-size: 14px;
  }

  .warning_text {
    font-size: 7px !important;
  }

  .CustomerCStxt {
    font-size: 23px !important;
  }

  .imgTextMOV {
    font-size: 11px !important;
  }

  .imgText2MOV {
    font-size: 9px !important;
  }

  .zoomMob {
    width: 69px !important;
  }

  .explImgMOV {
    width: 87px !important;
    height: 87px !important;
  }

  .explImg2MOV {
    width: 73px !important;
    height: 91px !important;
  }

  .cataIConsCS {
    width: 60px;
    height: 60px;
  }

  .explImgMOV {
    width: 77px !important;
    height: 77px !important;
  }
}

@media screen and (max-width: 290px) {
  .cataText {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 350px) {
  .footerline {
    font-size: 6px !important;
  }
}



/* -------------------------------------------------------------Footer------------------------------------------------------------- */


/* .slick-slide.slick-center img {
  transform: scale(1.6);
} */
.center {
  transform: scale(1.6);
}

/* .slick-slide.slick-center img {
  margin-left: auto;
  margin-right: auto;
} */
.bxShdwMOV {
  box-shadow: 0px 0px 2.33567px 1.16783px rgba(0, 0, 0, 0.16);
  border-radius: 4.31383px;
}

:focus-visible {
  outline: none !important;
}

.autoWidth {
  width: -webkit-fill-available !important;
}

.explImg2MOV:hover {
  width: 147px;
  height: 167px;
}

.zoomMob {
  transition: transform 0.5s;
  width: 122px;
  height: 167px;
  margin: 50px 0;
  /* transform: scale(1.4) !important; */
}

.emptyDiv1 {
  width: auto;
  height: 50px;
  background-color: gainsboro;
}

.emptyDiv2 {
  width: 100%;
  height: 81px;
  background-color: gainsboro;
}

.emptyDiv3 {
  width: 100%;
  height: 24px;
  background-color: gainsboro;
}

.emptyDiv4 {
  width: 100%;
  height: 49px;
  background-color: gainsboro;
}

.heartNdelicon {
  height: 16px;
  width: 18px;
}

.cataText {
  font-size: 14px;
 font-family: 'inter';
  width: max-content;
}

/* .cate_coll_w .nav-item {
  width: 50% !important;
  text-align: -webkit-center;
} */

.cate_coll_border_0 .nav-tabs .nav-link {
  border: 0px !important;
}

.pt2Nav .nav-link {
  padding: 0px !important;
  padding-top: .25rem !important;
}

.cate_coll_text_clr_0,
.nav-link,
.nav-link:focus,
.nav-link:hover {
  color: #000000 !important;
  font-weight: 300;
}

.cate_coll_text_act.clr .nav-link.active {
  color: #000 !important;
  background-color: transparent !important;
  border-bottom: 2px solid #737373 !important;
  font-weight: 900 !important;
}

.cate_coll_img {
  max-height: 160px;
  padding: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
}

.nav-link:focus,
.nav-link:hover {
  font-weight: 900 !important;
}

/* .cate_coll_img_f:focus {
  border: 1px solid #1ffc33 !important;
} */

a {
  text-decoration: none !important;
  color: #000 !important;
}

/* / ------------------------------footer support----------------------------- / */
.supportClass {
  padding: 0px !important;
  line-height: 19px !important;
  background-color: white !important;
  border-color: white !important;
  color: #000 !important;
}

.supportClass .btn-primary {
  background-color: none !important;
  border-color: none !important;
  color: #000 !important;
}

.supportClass .btn-primary:hover {
  background-color: transparent !important;
  border-color: none !important;
  color: #000 !important;
}

.btnDrop .dropdown-toggle::after {
  display: none !important;
}

.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdownMenu.dropdown-menu {
  min-width: 2.7rem !important;
}

/* .dropdownMenu {
  right: 30% !important;
} */


/* ----------------------------------------------------------------------------Rajat---------------------------------------------------------------------------- */
.img_h {
  height: 261px;
}

.img_WH {
  width: 70px;
  height: 70px;
}


.textdark {
  background-color: #3A3A3A;
}

.mutetedText {
  color: #3A3A3A;
}

.fs7 {
  font-size: 6px;
}

.fs10 {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs24 {
  font-size: 24px;
}

.fs27 {
  font-size: 27px;
}

.ls34 {
  line-height: 34px;
}

.ls27 {
  line-height: 27px;
}

.ls20 {
  line-height: 20px;
}

.ls15 {
  line-height: 15px;
}

.ls12 {
  line-height: 12px;
}

.ls5 {
  line-height: 5px;
}

.ls1em {
  letter-spacing: 0.1em;
}

.ls06em {
  letter-spacing: 0.06em;
}

.ls13em {
  letter-spacing: 0.13em;
}

.mt7 {
  margin-top: 7px;
}

.mt12 {
  margin-top: 12px;
}

.mh228 {
  max-height: 228px;
  overflow: auto;
}

.imgShadow {
  box-shadow: 0px 0px 2.33567px 1.16783px rgba(0, 0, 0, 0.16);
}

.ps_7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pe_7 {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.footerBorder {
  border-top: .5px solid #3A3A3A;
}

.headDemobr {
  border-bottom: 0.5px solid;
  width: fit-content;
}

.mb35 {
  margin-bottom: 35px !important;
}

.text_fs {
  width: 63px;
  height: 18px;
  font-size: 14px;
  font-weight: 500;
}

.centerIcon {
  align-items: center;
}

.textAlign {
  text-align: center;
}

.cancel_B {
  width: 20px;
  height: 20px;
  float: right;
}

.modal_border {
  border: 0.608031px solid #d3d3d3 !important;
}

.textBox {
  width: 55px;
  height: 25px;
  outline: none;
  border-radius: 3px;
  border: 1px solid #7a7a7a;
}

/* ----------------------Filter-------------------- */
.inputQty {
  width: 50px;
  height: 16px;
  outline: none;
  border-radius: 3px;
  border: .7px solid #7a7a7a;
}

/* ----------------------ListGrid-------------------- */
.lgIconImgs {
  width: 18px;
  height: 18px;
}

/* ------------Footer----------------- */
.wdFooter {
  z-index: 1150 !important;
}

/* ---------------------Category 390px------------------------- */
@media screen and (max-width: 390px) {
  .widthCataImg {
    width: 65px;
    height: 65px;
  }

  .fs20 {
    font-size: 18px;
  }

  .mhw_cat_img {
    height: 100px;
    width: 124px;
  }

  .left_Menu_fs {
    font-size: 14px;
    cursor: pointer;
  }

  .left_Menu_mb {
    margin-bottom: .75rem
  }

  .productDesImg {
    max-height: 500px;
  }

  .videoTab {
    height: 195px !important;
  }

  .mtN3 {
    margin-top: -3px;
  }

  .priceText {
    font-size: 15px !important;
    width: max-content !important;
  }

}

@media screen and (min-width: 391px) {

  .mhw_cat_img {
    height: 124px;
    width: 124px;
  }

  .left_Menu_fs {
    font-size: 18px;
    cursor: pointer;
  }

  .left_Menu_mb {
    margin-bottom: 1rem
  }
}


.mtN20 {
  margin-top: -20px;
}

.videoTab {
  height: 217px;
}


/* ------------------left menu-------------------------------------------------- */
.offcanvas-start {
  width: 280px !important;
}

/* .offcanvas{
  z-index: 1060!important;
} */
.left_Menu {
  margin-bottom: 10.8% !important;
}

.bottom20 {
  bottom: 20px
}

.mw600 {
  max-width: 600px;
  margin: auto;
}

.fs12 {
  font-size: 12px;
}

.new {
  margin-bottom: -10px;
  font-style: italic;
}

.border1 {
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  box-shadow: 1px 0px 5px 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 0px !important;
}

.aadbtn {
  /* width: 179px; */
  height: 38px;
  background: #1ffc33;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 16px;
}

.catagoryZindex {
  z-index: 1047;
}

/* ------------------------------------------realted------------------------------ */
.img261 {
  min-height: 261px
}

.img534 {
  height: 543px
}

.input_search {
  background-image: url('https://wallicon.in/assets/icons/filter.svg');
  /* background-size:contain; */
  background-repeat: no-repeat;
  text-indent: 20px;
  /* Extra Styling */
  /* padding: 5px 3px; */
  transition: 0.3s;
  background-position: left center;
}

.input_search:focus {
  background-image: none;
  text-indent: 0px
}

.mb50 {
  margin-bottom: 50px !important;
}

.height22 {
  height: 22px;
}

/* / ---------------------------------------About---------------------------------------- / */
.border_Img {
  border: 0.246063px solid #e5e5e5 !important;
  bottom: -32px;
  left: 3px;
}

.fs_20 {
  font-size: 20px !important;
}

.fs_24 {
  font-size: 24px !important;
}

.fs_40 {
  font-size: 40px !important;
}

.fs_50 {
  font-size: 50px !important;
}

.max_width {
  max-width: 1920px !important;
}

.minWidth {
  min-width: 360px !important;
}

/* / / ------------------------------DesktopView AboutUs-------------------------------------- / / */
.max_min_width {
  max-width: 1920px !important;
  min-width: 360px !important;
  margin: auto;
}

.LandingImg {
  width: 100% !important;
  object-fit: cover !important;
  max-height: 680px !important;
  min-height: 222px !important;
}

.img_HW {
  width: 137px;
  height: 137px;
}

.aboutDealerText_20 {
  font-size: 20px !important;
}

.aboutDealerText_16 {
  font-size: 16px !important;
}

.margin_120 {
  margin-left: 120px;
}

.marginContact {
  margin-left: 203px;
  margin-right: 120px;
  margin-bottom: 120px;
}

.margin_text {
  margin-left: 430px;
  margin-right: 430px;
}

.margin_MapImage {
  margin-left: 120px;
}

.margin_20 {
  margin-top: 20px;
}

.margin_Col {
  margin-left: 20px;
  margin-right: 20px;
}

.AboutUs_fs40 {
  font-size: 40px !important;
}

.border_Img {
  border: 0.5px solid #e5e5e5 !important;
  border-radius: 2px;
}

.border_text {
  width: 350px;
  height: 160px;
  border: 0.5px solid #e5e5e5 !important;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
}

.border_text1 {
  width: 350px;
  height: 160px;
  border: 0.5px solid #e5e5e5 !important;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
}

.About_fs24 {
  font-size: 24px !important;
}

.About_fs50 {
  font-size: 50px !important;
}

.AboutUs_fs20 {
  font-size: 20px !important;
}

.AboutUsDemo_fs20 {
  font-size: 20px !important;
  font-weight: bold !important;
}

/* / / ----------------------------1900px--------------------------- / / */
@media screen and (max-width: 1900px) {
  .AboutUs_fs25 {
    font-size: 23px;
  }
}

/* / / ----------------------------1800px--------------------------- / / */
@media screen and (max-width: 1820px) {
  .AboutUs_fs25 {
    font-size: 21px;
  }
}

/* / ----------------------------1680px--------------------------- / */
@media screen and (max-width: 1680px) {
  .marginContact {
    margin-left: 155px;
    margin-right: 90px;
    margin-bottom: 109px;
  }

  .margin_text {
    margin-left: 215px;
    margin-right: 215px;
  }
}

/* / / -----------------------------------1560px-------------------------- / / */

@media screen and (max-width: 1560px) {
  .margin_MapImage {
    margin-left: 90px;
  }

  .AboutUs_fs25 {
    font-size: 16px;
  }
}

/* / / ----------------------------1440px--------------------------- / / */
@media screen and (max-width: 1440px) {
  .margin_MapImage {
    margin-left: 90px;
  }

  .marginContact {
    margin-left: 135px;
    margin-right: 90px;
    margin-bottom: 80px;
  }

  .margin_text {
    margin-left: 180px;
    margin-right: 180px;
  }

  .AboutUs_fs25 {
    font-size: 18px;
  }
}

/* / / ----------------------------1380px--------------------------- / / */
@media screen and (max-width: 1380px) {
  .margin_MapImage {
    margin-left: 80px;
  }

  .border_text {
    width: 325px;
    height: 160px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .border_text1 {
    width: 325px;
    height: 160px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .margin_text {
    margin-left: 199px;
    margin-right: 199px;
  }

  .margin_20 {
    margin-top: 10px;
  }

  .margin_Col {
    margin-left: 10px;
    margin-right: 10px;
  }

  .AboutUs_fs20 {
    font-size: 18px !important;
  }

  .AboutUsDemo_fs20 {
    font-size: 18px !important;
  }

  .AboutUs_fs25 {
    font-size: 16px;
  }
}

/* / / ----------------------------1280px--------------------------- / / */
@media screen and (max-width: 1280px) {
  .margin_MapImage {
    margin-left: 60px;
  }

  .img_HW {
    width: 100px;
    height: 100px;
  }

  .aboutDealerText_20 {
    font-size: 16px !important;
  }

  .aboutDealerText_16 {
    font-size: 13px !important;
  }

  .marginContact {
    margin-left: 105px;
    margin-right: 75px;
    margin-bottom: 55px;
  }

  .margin_text {
    margin-left: 75px;
    margin-right: 75px;
  }

  .AboutUs_fs25 {
    font-size: 14px;
  }
}

/* / / ----------------------------1124px--------------------------- / / */
@media screen and (max-width: 1124px) {
  .margin_MapImage {
    margin-left: 30px;
  }

  .border_text {
    width: 290px;
    height: 160px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .border_text1 {
    width: 290px;
    height: 160px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .marginContact {
    margin-left: 65px;
    margin-right: 0px;
    margin-bottom: 32px;
  }

  .margin_text {
    margin-left: 20px;
    margin-right: 20px;
  }

  .AboutUs_fs25 {
    font-size: 16px;
  }
}

/* / / ----------------------------980px--------------------------- / / */
@media screen and (max-width: 980px) {
  .border_text {
    width: 242px;
    height: 125px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .border_text1 {
    width: 242px;
    height: 125px;
    border: 0.5px solid #e5e5e5 !important;
    border-radius: 5px;
  }

  .margin_MapImage {
    margin-left: 30px;
  }

  .marginContact {
    margin-left: 31px;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .margin_text {
    margin-left: 75px;
    margin-right: 75px;
  }

  .iconHW {
    width: 40px !important;
    height: 40px !important;
    margin-top: 0.5rem !important;
  }

  .AboutUs_fs20 {
    font-size: 14px !important;
  }

  .AboutUsDemo_fs20 {
    font-size: 14px !important;
  }

  .AboutUs_fs25 {
    font-size: 14px !important;
  }

  .AboutUs_fs40 {
    font-size: 35px !important;
  }
}

/* / / ----------------------------880px--------------------------- / / */
@media screen and (max-width: 880px) {
  .margin_MapImage {
    margin-left: 0px;
  }

  .marginContact {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .margin_text {
    margin-left: 0px;
    margin-right: 0px;
  }

  .AboutUs_fs25 {
    font-size: 14px;
  }

  .AboutUs_fs40 {
    font-size: 35px;
  }
}

/* / / ---------------------------------- 768px-------------------------------- / / */
@media screen and (max-width: 768px) {
  .border_text {
    width: 260px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    border: 0px solid #e5e5e5 !important;
    border-radius: 0px;
  }

  .img_HW {
    width: 79px !important;
    height: 79px !important;
  }

  .aboutDealerText_20 {
    font-size: 12px !important;
  }

  .aboutDealerText_16 {
    font-size: 10px !important;
  }

  .iconHW {
    margin-top: 0.25rem !important;
  }

  .border_text1 {
    /* width: 242px;
    height: 125px; */
    width: 260px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    border: 0px solid #e5e5e5 !important;
    border-radius: 0px;
  }

  .margin_50 {
    margin-top: 0px;
  }

  .text_start {
    text-align: start;
  }
}

/* / / ---------------------------------- 600px-------------------------------- / / */
@media screen and (max-width: 600px) {
  .About_fs24 {
    font-size: 20px !important;
  }

  .border_text {
    height: 125px !important;
  }

  .border_text1 {
    height: 100px !important;
  }

  .About_fs50 {
    font-size: 40px !important;
  }

  .AboutUsDemo_fs20 {
    font-size: 14px !important;
    text-align: start !important;
    font-weight: 300 !important;
  }

  .AboutUs_fs20 {
    font-size: 16px !important;
  }

  .AboutUs_fs25 {
    font-size: 16px !important;
  }

  .AboutUs_fs40 {
    font-size: 24px !important;
  }

  .margin_50 {
    margin: 0px !important;
  }

  .margin_Col {
    margin: 0px !important;
  }

  .text_start {
    text-align: start;
  }
}

/* / -----------------------------------------Photo Gallery----------------------------------------------------- / */
.textGreen {
  color: #1ffc33 !important;
}

.img2 {
  height: 310px !important;
  max-width: 50% !important;
}

.img3 {
  height: 203px !important;
  max-width: 33.3% !important;
}

.colorTxt {
  color: #252525 !important;
  font-weight: 300;
}

.text_img {
  position: relative;
  width: 100%;
}

.txtOpa {
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
  text-decoration: underline;
  font-size: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.imgSize1 {
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  height: 203px;
  width: 100% !important;
  border-radius: 3px;
}

.heightSaleOrder {
  height: 400px !important;
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .photoGal {
    display: none !important;
  }

  .photoGalMov {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .photoGal {
    display: block !important;
  }

  .photoGalMov {
    display: none !important;
  }
}