body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'inter';
}

@font-face {
font-family: 'inter';

}

@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display"),
    url("./components/fonts/playFair/PlayfairDisplay-Regular.ttf") format("truetype");

}

.cp {
font-family: 'inter';
}

.cpm {
 font-family: 'inter';
}

.pf {
 font-family: 'inter';
}